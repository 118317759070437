<template>
  <DataList
    endpoint="/api-backend/domiciliation-orders/attente-embarquement"
    :params="params"
    :wantedData="wantedData"
    disable-edit
    disable-delete
    embarquement
  />
</template>
<script>
import DataList from '@/components/complex-table/DataList.vue'

export default {
  name: 'Attente Embarquement',
  title: 'Liste des Clients Attente Embarquement',
  components: {
    DataList,
  },
  data() {
    return {
      wantedData: [
        {
          name: 'id',
          title: 'ID',
        },
        {
          name: 'orderDate',
          title: 'Date de commande',
          type: 'date',
        },
        {
          name: 'nomSociete',
          title: 'Nom Societe',
        },
        {
          name: 'nomComplet',
          title: 'Nom Complet',
        },
        {
          name: 'allNames',
          title: 'Dirigeant',
        },
        {
          name: 'label.name',
          title: 'Marque blanche',
          type: 'label',
        },
        {
          name: 'user.email',
          title: 'Adresse Mail',
        },
        {
          name: 'user.phone',
          title: 'Téléphone',
        },
        {
          name: 'adresse.name',
          title: 'Domiciliation',
        },
        {
          name: 'commercial',
          title: 'Commercial',
        },
        {
          name: 'commentaire',
          title: 'Commentaire',
        },
        {
          name: 'status',
          title: 'Status',
        },
        {
          name: 'user.customerId',
          title: 'L\'client Stripe',
        },
        {
          name: 'subscriptionId',
          title: 'Abonnement Stripe',
        },
        {
          name: 'dueDate',
          title: 'Date d\'échéance',
          type: 'date',
        },
        {
          name: 'kbis',
          title: 'Kbis',
          type: 'url',
        },
        {
          name: 'embarquement',
          title: 'Embarquement',
          type: 'embarquement',
          force: true,
        },
        {
          name: 'couponCode',
          title: 'Coupon utilisé',
        },
      ],
      params: {},
    }
  },
}
</script>
